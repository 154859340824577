body {
  font-family: "HeptaSlabRegular";
  background-color: white;
}

h1 {
  padding: 0px;
}
.home-container {
  min-height: 600px;
  min-width: 250px;
  position: fixed;
  margin-top: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.content-container {
  position: absolute;
  width: 75%;
  height: 80%;
  text-align: center;
}

.logo {
  background: url(../assets/logo.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.logo-text {
  margin-top: -10px;
}

.home-footer {
  position: fixed;
  right: 0;
  display: flex;
  justify-content: baseline;
}

.menu-button-home {
  z-index: 999999999999999;
  position: fixed;
  left: 10px;
  top: 30px;
  background: url(../assets/menu-botton-home.svg);
  background-repeat: no-repeat;
  background-size: auto;
}

.facebook-icon {
  background: url(../assets/facebook.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.instagram-icon {
  background: url(../assets/instagram.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  margin: 0 auto;
}

.icon-title {
  border-bottom: 5px solid black;
  line-height: 70px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  /* Mobile */
  .icon-title {
    font-size: 30px;
    padding-bottom: 15px;
    width: 250px;
  }
  .icon-sub-title {
    font-size: 12px;
    width: 100%;
    padding: 10px;
    margin: 20px auto 0px -10px;
  }

  .click-me-button-pressed {
    background: url(../assets/clickme-pressed.svg);
    background-repeat: no-repeat;
    background-size: auto;
    margin: 20px auto;
    width: 250px;
    height: 150px;
  }

  .click-me-button {
    background: url(../assets/clickme.svg);
    background-repeat: no-repeat;
    background-size: auto;
    margin: 20px auto;
    width: 250px;
    height: 150px;
  }

  .menu-button-home {
    width: 60px;
    height: 60px;
  }
  .facebook-icon {
    width: 30px;
  }
  .instagram-icon {
    width: 30px;
  }
  .home-footer {
    bottom: 5px;
    font-size: 12px;
    padding-right: 20px;
  }
  .home-footer p {
    padding: 4px;
  }
  .logo {
    height: 100px;
  }
  .content-container {
    margin-top: 40px;
    width: 90%;
  }
}

@media only screen and (min-width: 600px) {
  /* Desktop */

  .icon-title {
    font-size: 50px;
    padding-bottom: 25px;
    width: 300px;
  }
  .icon-sub-title {
    font-size: 15px;
    width: 100%;
    padding: 10px;
    margin: 20px auto 0px -10px;
  }
  .click-me-button {
    background: url(../assets/clickme.svg);
    background-repeat: no-repeat;
    background-size: auto;
    margin: 20px auto;
    width: 300px;
    height: 200px;
  }
  .click-me-button-pressed {
    background: url(../assets/clickme-pressed.svg);
    background-repeat: no-repeat;
    background-size: auto;
    margin: 20px auto;
    width: 300px;
    height: 200px;
  }

  .menu-button-home {
    width: 110px;
    height: 110px;
  }
  .logo {
    width: 100%;
    height: 200px;
  }
  .facebook-icon {
    width: 50px;
  }
  .instagram-icon {
    width: 60px;
  }
  .home-footer {
    bottom: 5px;
    font-size: 15px;
    padding-right: 20px;
  }
  .home-footer p {
    padding: 4px;
  }
}
