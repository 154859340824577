.menu-button-video {
  z-index: 999999999999999;
  width: 110px;
  height: 110px;
  position: fixed;
  left: 10px;
  top: 30px;
  background: url(../assets/menu-botton-video.svg);
  background-repeat: no-repeat;
  background-size: auto;
}

.instagram-white-icon {
  background: url(../assets/instagram-white.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  margin: 0 auto;
}

.generate-btn-pressed {
  background: url(../assets/generate-pressed.svg);
  background-repeat: no-repeat;
  background-size: auto;
  margin: 20px auto;
}

.generate-btn {
  background: url(../assets/generate.svg);
  background-repeat: no-repeat;
  background-size: auto;
  margin: 20px auto;
}

.text-container{
  position: absolute;
  width: 100%;
  margin: 0 auto;
}

.video-content{
  
  position: fixed;
  width: 100%;
  height: 100%;
  margin: -10px;
  padding: 0px 10px 10px 0px ;
  background-color: black;
  color: white;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

video {
  width: 101%;
  margin: auto 0;
  position: relative;
  border: none;
  outline: 0;
  display: hidden;
}


.facebook-video-icon {
  background: url(../assets/facebook.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}


@media only screen and (max-width: 600px) {
  /* Mobile */
  .menu-button-video {
    width: 60px;
    height: 60px;
  }
  .generate-btn-pressed {
    width: 250px;
    height: 150px;
  }
  .generate-btn {
    width: 250px;
    height: 150px;
  }
  .share-container {
    display: flex;
    justify-content: baseline;
    width: 250px;
    margin-left: calc(50% - 100px );
    margin-top:-40px;
  }

  .share-container {
    display: flex;
    justify-content: baseline;
    width: 257px;
    margin-left: calc(50% - 120px );
    margin-top:-40px;
  }  

  .facebook-video-icon{
    width: 60px;
  }
  .instagram-white-icon{
    width: 60px;
    margin-right: -8px;
  }
}

@media only screen and (min-width: 600px) {
  /* Desktop */
  .menu-button-video {
    width: 110px;
    height: 110px;
  }
  .generate-btn-pressed {
    width: 300px;
    height: 200px;
  }
  .generate-btn {
    width: 300px;
    height: 200px;
  }
  .share-container {
    display: flex;
    justify-content: baseline;
    width: 240px;
    margin-left: calc(50% - 120px );
    margin-top:-40px;
  } 

  .share-container .facebook-video-icon{
    width: 40px;
  }
  .share-container .instagram-white-icon{
    width: 40px;
  }
}