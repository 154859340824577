body{
  background-color: white;
}

.about-container {
  text-align: center;
}

.menu-button-about {
  z-index: 999999999999999;
  position: absolute;
  left: 10px;
  top: 30px;
  background: url(../assets/menu-botton-about.svg);
  background-repeat: no-repeat;
  background-size: auto;
}

.about-container {
  margin: 0 auto;
  padding-bottom: 50px;
}

.signature {
  font-family: "VcrOcdMono";
  float: right;
}

p {
  text-align: center;
  line-height: 22px;
}

.title {
  padding: 15px;
  border-bottom: 3px solid black;
  width: 120px;
  margin: 0 auto;
  font-size: 20px;
}

@media only screen and (min-width: 600px) {
  /* Desktop */
  .menu-button-home {
    width: 110px;
    height: 110px;
  }
  .about-container {
    margin: 0 auto;
    margin-top: 110px;
  }
  
  .menu-button-about {
    width: 110px;
    height: 110px;
  }
  .about-container {
    width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  /* Mobile */
  .menu-button-about {
    width: 60px;
    height: 60px;
  }
  .about-container {
    margin: 0 auto;
    margin-top: calc(60px)
  }
  .about-container {
    width: 300px;
  }
}