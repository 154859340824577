.main-container {
  z-index: 999999999999999;
  width: 50px;
  height: 50px;
  position: fixed;
  left: 10px;
  top: 10px;
  max-width: 375px;
  min-width: 375px;
  max-height: 812px;
  min-height: 812px;
}

@font-face {
  font-family: "MenloRegular";
  src: url("../assets/fonts/Menlo-Regular.ttf");
}

@font-face {
  font-family: "VcrOcdMono";
  src: url("../assets/fonts/VcrOcdMono.ttf");
}

@font-face {
  font-family: "HeptaSlabRegular";
  src: url("../assets/fonts/HeptaSlab-Regular.ttf");
}

body {
  font-family: "MenloRegular";
}

.hidden{
  display: none;
}

.shown{
  display: block;
}

/* Unused fonts

@font-face {
  font-family: "BioRhymeRegular";
  src: url("../assets/fonts/BioRhyme-Regular.ttf");
}

@font-face {
  font-family: "BioRhymeLight";
  src: url("../assets/fonts/BioRhyme-Light.ttf");
}
*/
